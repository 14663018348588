import React from 'react';
import { ReactComponent as SSLogo } from '../views/icons/brands/ss-logo-white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CButton } from '@coreui/react';
import queryString from 'query-string';
import CIcon from '@coreui/icons-react';
import { addQuery, removeQuery, getGoFSettings, updateGofUserSettings, getLsDateZone, slugify, toLocalTimeZone } from './global';
import moment from 'moment';
import { commonActions } from 'src/store/_actions/common.actions';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { startOfDay, subHours, subDays } from 'date-fns';


export const jobTypesMappedNames = {
    'OrderInformationUpdateJob': 'Order',
    'OrderInformationUpdateJobGOF': 'Order GOF',
    'InStoreOrderInformationService': 'Order POS',
    'FulfilmentInformationSoapService': 'Fulfillment',
    'ShippingConfirmationUpdateJob': 'Shipping',
    'BillingInformationUpdateJob': 'Billing',
    'CreateLabel': 'Shipping Label',
    'CaptureFundsSoapService': 'Capture funds',
    'CatalogInformationSoapService': 'Catalog',
    'CustomerInStorePurchaseService': 'Customer Purchase',
    'CustomerInformationUpdateJob': 'Customer Outbound',
    'FinancialReportsImportJob': 'Fin. reports',
    'GiftCardSoapApiService': 'GC',
    'NewCustomerSoapService': 'Customer New Inbound',
    'ProcessBufferedStyleItems': 'Magento Configurable',
    'ProcessBufferedVariantItems': 'Magento Simple',
    'ShipmentJob': 'GOF Shipment',
    'MagentoShipmentJob': 'Magento Shipment',
    'ShipStationPrepareDataJob': 'ShipStation Prep',
    'ShipStationCreateLabelJob': 'ShipStation Label',
    'CustomerInformationSoapService': 'Customer Inbound',
    'InStoreMoneyCardPurchaseService': 'GC In Store',
    'NewAddressSoapService': 'New Address',
    'SubscribeEmailInformationUpdateJob': 'Email Subscription',
    'CsvImportResultUpdateJob': 'CSV Import',
    'SubscribeEmailInformationSoapService': 'Email Subscribe',
    'BillingInformationActiveGiftCard': 'GC Activate',
    'CreditmemoReloadGiftCardGOF': 'GC Reload',
    'OrderInformationActiveGiftCard': 'Active Gift Card Order',
    'ShipStationShipmentJob': 'ShipStation Shipment',
    'InStoreShipStationJob': 'ShipStation In Store',
    'DeeleeoCreateLabelJob': 'Deeleeo Label',
    'ShopifyCreateOrderJob': 'Shopify Order',
    'ShopifyFulfillmentJob': 'Shopify Fulfillment',
    'ShopifyRefundJob': 'Shopify Refund',
}

export const jobTypesMappedPayloadsOptions = {
    'Order': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'Order GOF': {
        xml_request: { show: false, label: 'XML Request' },
        xml_response: { show: false, label: 'XML Response' },
        commandPayload: { show: true, label: 'Payload' },
        data: { show: true, label: 'Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'Order POS': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: false, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: false, label: 'Result' },
        exception: { show: false, label: 'Exception' },
    },
    'Fulfillment': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: false, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: false, label: 'Result' },
        exception: { show: false, label: 'Exception' },
    },
    'Shipping': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'Billing': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'Shipping Label': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'CaptureFundsSoapService': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'CatalogInformationSoapService': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'CustomerInStorePurchaseService': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'CustomerInformationUpdateJob': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'FinancialReportsImportJob': {
        commandPayload: { show: true, label: 'Payload' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
        data: { show: true, label: 'Data' },
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
    },
    'GiftCardSoapApiService': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'NewCustomerSoapService': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'ProcessBufferedStyleItems': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'ProcessBufferedVariantItems': {
        xml_request: { show: true, label: 'XML Request' },
        xml_response: { show: true, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
    'MagentoShipmentJob': {
        xml_request: { show: false, label: 'XML Request' },
        xml_response: { show: false, label: 'XML Response' },
        commandPayload: { show: true, label: 'JSON Request' },
        data: { show: true, label: 'JSON Response' },
        result: { show: true, label: 'Result' },
        exception: { show: true, label: 'Exception' },
    },
}

export const getJobShortName = (job) => {
    const jobName = job.name;
    const jobNameParts = jobName.split('\\');

    if (jobNameParts.length) {
        return jobTypesMappedNames[jobNameParts[jobNameParts.length - 1]];
    }

    return jobName;
}

export const getSSLogo = () => {
    return SSLogo;
}

export const getJobIcon = (job) => {
    // switch (job.name) {
    //     case 'App\\Jobs\\ShipmentJob':
    //         return <div style={{ width: "30px", height: "30px" }}><SSLogo /></div>;
    //     case 'App\\Jobs\\OrderInformationUpdateJob':
    //         return <FontAwesomeIcon color="#f36306" style={{ fontSize: '25px' }} icon={faMagento} />
    //     case 'App\\Jobs\\BillingInformationUpdateJob':
    //         return <FontAwesomeIcon color="#f36306" style={{ fontSize: '25px' }} icon={faMagento} />
    //     case 'App\\Jobs\\ShippingConfirmationUpdateJob':
    //         return <FontAwesomeIcon color="#f36306" style={{ fontSize: '25px' }} icon={faMagento} />
    //     case 'App\\Jobs\\OrderInformationUpdateJobGOF':
    //             return <FontAwesomeIcon color="#f36306" style={{ fontSize: '25px' }} icon={faMagento} />
    //     case 'App\\Services\\SOAP\\Inbound\\FulfilmentInformationSoapService':
    //         return <div style={{ width: "30px", height: "30px" }}><AcceoLogo style={{ width: "30px", height: "30px" }} /></div>;
    //     case 'App\\Services\\SOAP\\Inbound\\InStoreOrderInformationService':
    //         return <div style={{ width: "30px", height: "30px" }}><AcceoLogo style={{ width: "30px", height: "30px" }} /></div>;
    //     case 'App\\Services\\SOAP\\Inbound\\ShipStationShipmentJob':
    //         return <div style={{ width: "30px", height: "30px" }}><AcceoLogo style={{ width: "30px", height: "30px" }} /></div>;
    //     case 'App\\Services\\SOAP\\Inbound\\InStoreShipStationJob':
    //         return <div style={{ width: "30px", height: "30px" }}><AcceoLogo style={{ width: "30px", height: "30px" }} /></div>;
    //     default:
    //         return <FontAwesomeIcon color="#f36306" style={{ fontSize: '25px' }} icon={faMagento} />
    // }
}

export const getJobUser = (job) => {
    const userPlaceholder = 'API User';
    switch (job.name) {
        case 'App\\Jobs\\ShipmentJob':
            return job.username ??= 'Staff user';
        case 'App\\Jobs\\MagentoShipmentJob':
            return job.username ??= 'Staff user';
        case 'App\\Jobs\\ShipStationPrepareDataJob':
            return job.username ??= 'Staff user';
        case 'App\\Jobs\\ShipStationCreateLabelJob':
            return job.username ??= 'Staff user';
        case 'App\\Jobs\\DeeleeoCreateLabelJob':
            return job.username ??= 'Staff user';
        case 'App\\Jobs\\CreateLabelCleanupJob':
            return job.username ??= 'Staff user';
        case 'GoF\\CreateLabel':
            return job.username ??= userPlaceholder;
        case 'App\\Jobs\\OrderInformationUpdateJob':
            return userPlaceholder;
        case 'App\\Jobs\\BillingInformationUpdateJob':
            return userPlaceholder;
        case 'App\\Jobs\\ShippingConfirmationUpdateJob':
            return userPlaceholder;
        case 'App\\Services\\SOAP\\Inbound\\FulfilmentInformationSoapService':
            return userPlaceholder;
        default:
            return userPlaceholder
    }
}

export const getFinishedJobsColumns = () => {
    return [];
}

export const orderJobTypes = () => {
    return [
        'App\\Jobs\\OrderInformationUpdateJob',
        'App\\Services\\SOAP\\Inbound\\InStoreOrderInformationService',
        'App\\Jobs\\OrderInformationUpdateJobGOF',
        'App\\Services\\SOAP\\Inbound\\FulfilmentInformationSoapService',
        'App\\Jobs\\ShippingConfirmationUpdateJob',
        'App\\Jobs\\BillingInformationUpdateJob',
        'GoF\\CreateLabel',
        'App\\Services\\SOAP\\Outbound\\GiftCardSoapApiService',
        'App\\Jobs\\OrderInformationActiveGiftCard',
        'App\\Jobs\\CreditmemoReloadGiftCardGOF',
        'App\\Jobs\\BillingInformationActiveGiftCard',
        'App\\Jobs\\ShipmentJob',
        'App\\Jobs\\MagentoShipmentJob',
        'App\\Jobs\\ShipStationPrepareDataJob',
        'App\\Jobs\\ShipStationCreateLabelJob',
        'App\\Jobs\\DeeleeoCreateLabelJob',
    ];
}

export const catalogJobTypes = () => {
    return [
        'App\\Services\\SOAP\\Inbound\\CatalogInformationSoapService',
        'App\\Jobs\\ProcessBufferedStyleItems',
        'App\\Jobs\\ProcessBufferedVariantItems',
    ];
}

export const otherJobTypes = () => {
    return [
        'App\\Services\\SOAP\\Inbound\\CaptureFundsSoapService',
        'App\\Services\\SOAP\\Inbound\\CustomerInStorePurchaseService',
        'App\\Jobs\\CustomerInformationUpdateJob',
        'App\\Jobs\\FinancialReportsImportJob',
        'App\\Services\\SOAP\\Outbound\\GiftCardSoapApiService',
        'App\\Services\\SOAP\\Inbound\\NewCustomerSoapService',
        'App\\Jobs\\CustomerInformationSoapService',
        'App\\Jobs\\InStoreMoneyCardPurchaseService',
        'App\\Jobs\\NewAddressSoapService',
        'App\\Jobs\\SubscribeEmailInformationUpdateJob',
        'App\\Jobs\\SubscribeEmailInformationSoapService',
        'App\\Jobs\\CsvImportResultUpdateJob'
    ]
}

export const getStatusLabel = (value) => {
    if (value === '0') {
        return 'Success';
    }
    else if (value === '1') {
        return 'Failed';
    }
    else if (value === '2') {
        return 'Warning';
    }
    else if (value === 'a') {
        return 'All';
    }
    return '';
}

export const defaultColumnWidths = [
    {
        "columnName": "id",
        "width": 180
    },
    {
        "columnName": "job_id",
        "width": 180
    },
    {
        "columnName": "name",
        "width": 400
    },
    {
        "columnName": "queue",
        "width": 180
    },
    {
        "columnName": "started_at",
        "width": 260
    },
    {
        "columnName": "started_at_exact",
        "width": 180
    },
    {
        "columnName": "finished_at",
        "width": 180
    },
    {
        "columnName": "finished_at_exact",
        "width": 180
    },
    {
        "columnName": "time_elapsed",
        "width": 180
    },
    {
        "columnName": "failed",
        "width": 180
    },
    {
        "columnName": "attempt",
        "width": 180
    },
    {
        "columnName": "status",
        "width": 200
    },
    {
        "columnName": "progress",
        "width": 180
    },
    {
        "columnName": "payload",
        "width": 180
    },
    {
        "columnName": "exception",
        "width": 180
    },
    {
        "columnName": "data",
        "width": 180
    },
    {
        "columnName": "xml_response",
        "width": 180
    },
    {
        "columnName": "xml_request",
        "width": 180
    },
    {
        "columnName": "order_id",
        "width": 180
    },
    {
        "columnName": "duration",
        "width": 180
    },
    {
        "columnName": "actions",
        "width": 180
    }
];

export const handleTimeZone = (date) => {
    const [lsDateZone] = getLsDateZone();

    if (lsDateZone === 'Local') {
        return toLocalTimeZone(date, ' 00:00');
    }

    return date;
}

export const getFinishJobsFiltersFromQuery = () => {
    const query = queryString.parse(window.location.search);

    const filters = [];

    // create filter and send it to setFinishJobsFilters to store into localStorage
    const orderJobTypesList = [];
    if (query?.transactionType) {
        // transactionTypes are separated by comma
        query?.transactionType.split(',').forEach(orderJobType => {

            let jobTypeSplit = orderJobType.split('\\');
            let label = '';
            let value = orderJobType;
            if (jobTypeSplit.length) {
                label = jobTypesMappedNames[jobTypeSplit[jobTypeSplit.length - 1]];
                value = jobTypeSplit[jobTypeSplit.length - 1];
            }

            if (label) {
                orderJobTypesList.push({ value: value, label: label });
            }

        });

        filters.push({ columnName: 'name', value: orderJobTypesList })
    }

    if (query?.status) {
        // statuses are separated by comma
        const statusList = [];
        query?.status.split(',').forEach(statusType => {
            const label = getStatusLabel(statusType);
            statusList.push({value: statusType, label: label})
        });

        filters.push({columnName: 'status', value: statusList});
    }

    const dateFilter = {'columnName': 'started_at', start_date: '', end_date: '', duration: '', duration_units: '' };

    if (query?.start_date) {
        dateFilter.start_date = query.start_date;
    }

    if (query?.end_date) {
        dateFilter.end_date = query.end_date;
    }

    if (query?.duration) {
        dateFilter.duration = query.duration;
    }

    if (query?.duration_units) {
        const labelVal = query.duration_units[0].toUpperCase() + query.duration_units.slice(1).toLowerCase();
        dateFilter.duration_units = { label: labelVal, value: query.duration_units }
    }

    filters.push(dateFilter);

    if (query?.orderNumber) {
        filters.push({ columnName: 'order_id', value: query?.orderNumber })
    }

    if (query?.sku) {
        filters.push({ columnName: 'sku', value: query?.sku })
    }

    if (query?.styleId) {
        filters.push({ columnName: 'style_id', value: query?.styleId })
    }

    if (query?.variantId) {
        filters.push({ columnName: 'variant_id', value: query?.variantId })
    }

    if (query?.magentoId) {
        filters.push({ columnName: 'magento_id', value: query?.magentoId })
    }

    if (query?.styleCode) {
        filters.push({ columnName: 'style_code', value: query?.styleCode })
    }

    if (query?.styleGroupCode) {
        filters.push({ columnName: 'style_group_code', value: query?.styleGroupCode })
    }

    if (query?.note) {
        filters.push({ columnName: 'note', value: query?.note })
    }

    if (query?.styleType) {
        filters.push({ columnName: 'style_type', value: query?.styleType })
    }

    if (query?.endpointUrl) {
        filters.push({ columnName: 'endpoint_url', value: query?.endpointUrl })
    }

    if (query?.catalogRequestId) {
        filters.push({ columnName: 'catalog_request_id', value: query?.catalogRequestId })
    }

    // broadcast to everyone
    return filters;
    // dispatch(commonActions.newFilterSettings(filters));
}

const getFullName = (name, jobTypesMap) => {
    const allJobTypesMap = Object.assign({}, ...jobTypesMap);
    let names = name.split(',');

    if (names && allJobTypesMap) {
        names = names.map(nm => {
            return allJobTypesMap[nm]
        });
    }
    return names.join(',');
}

export const setFinishJobsFiltersToQuery = (filterName, values, jobTypesMap = null) => {

    if (filterName === 'transaction_type' && jobTypesMap) {

        removeQuery('transactionType');

        let transaction = '';

        if (Array.isArray(values)) {
            const fullFilter = getFullName(values.map(param => param.value, jobTypesMap).join(','), jobTypesMap);
            transaction = values ? fullFilter : null;
        }

        if (transaction !== '' && transaction !== null) {
            addQuery('transactionType', transaction);
        }

        updateFinishJobsFiltersToStorage(filterName, transaction);
    }
    else if (filterName === 'date') {
        removeQuery('start_date');
        removeQuery('end_date');
        removeQuery('most_current');
        removeQuery('duration');
        removeQuery('duration_units');

        if (values?.start_date.length) {
            addQuery('start_date', values.start_date);
        }

        if (values?.end_date.length) {
            addQuery('end_date', values.end_date);
        }

        if (values?.duration.length) {
            addQuery('duration', values.duration)
        }

        if (values?.duration_units) {
            addQuery('duration_units', values.duration_units.value)
        }

        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if (filterName === 'status') {
        removeQuery('status');
        let status = [];
        if (Array.isArray(values)) {
            values.forEach(stat => {
                status.push(stat.value);
            });

            if (status.length > 0) {
                addQuery('status', status.join(','));
            }
        }
        updateFinishJobsFiltersToStorage(filterName, status.join(','));
    }
    else if (filterName === 'order_number') {
        removeQuery('orderNumber');
        if (values) {
            addQuery('orderNumber', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if (filterName === 'sku') {
        removeQuery('sku');
        if (values) {
            addQuery('sku', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if (filterName === 'style_id') {
        removeQuery('styleId');
        if (values) {
            addQuery('styleId', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if (filterName === 'variant_id') {
        removeQuery('variantId');
        if (values) {
            addQuery('variantId', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if (filterName === 'magento_id') {
        removeQuery('magentoId');
        if (values) {
            addQuery('magentoId', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if (filterName === 'style_code') {
        removeQuery('styleCode');
        if (values) {
            addQuery('styleCode', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if (filterName === 'style_group_code') {
        removeQuery('styleGroupCode');
        if (values) {
            addQuery('styleGroupCode', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if (filterName === 'note') {
        removeQuery('note');
        if (values) {
            addQuery('note', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if(filterName === 'style_type') {
        removeQuery('styleType');
        if (values) {
            addQuery('styleType', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if(filterName === 'endpoint_url') {
        removeQuery('endpointUrl');
        if (values) {
            addQuery('endpointUrl', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
    else if(filterName === 'catalog_request_id') {
        removeQuery('catalogRequestId');
        if (values) {
            addQuery('catalogRequestId', values);
        }
        updateFinishJobsFiltersToStorage(filterName, values);
    }
}

export const updateFinishJobsFiltersToStorage = (filterName, value) => {

    let storageName = '';

    if (filterName === 'transaction_type') {
        storageName = 'defaultFinishedJobsNameFilter';
    }
    else if (filterName === 'date') {
        storageName = 'defaultFinishedJobsDateFilter';
    }
    else if (filterName === 'status') {
        storageName = 'defaultFinishedJobsStatusFilter';
    }
    else if (filterName === 'order_number') {
        storageName = 'defaultFinishedJobsOrderFilter';
    }
    else if (filterName === 'sku') {
        storageName = 'defaultFinishedJobsSkuFilter';
    }
    else if (filterName === 'style_id') {
        storageName = 'defaultFinishedJobsStyleFilter';
    }
    else if (filterName === 'variant_id') {
        storageName = 'defaultFinishedJobsVariantFilter';
    }
    else if (filterName === 'magento_id') {
        storageName = 'defaultFinishedJobsMagentoFilter';
    }
    else if (filterName === 'style_code') {
        storageName = 'defaultFinishedJobsStyleCodeFilter';
    }
    else if (filterName === 'style_group_code') {
        storageName = 'defaultFinishedJobsStyleGroupCodeFilter';
    }
    else if (filterName === 'note') {
        storageName = 'defaultFinishedJobsNoteFilter';
    }
    else if (filterName === 'style_type') {
        storageName = 'defaultFinishedJobsStyleTypeFilter';
    }
    else if (filterName === 'endpoint_url') {
        storageName = 'defaultFinishedJobsEndpointUrlFilter';
    }
    else if (filterName === 'catalog_request_id') {
        storageName = 'defaultFinishedJobsCatalogRequestIdFilter';
    }

    if (storageName) {
        updateGofUserSettings(getGoFSettings(), storageName, value);
    }
}

export const setInitialFinishedJobsQuery = () => {

    // get current filter info
    const defaultFinishedJobsNameFilter = getGoFSettings('defaultFinishedJobsNameFilter');
    const defaultFinishedJobsDateFilter = getGoFSettings('defaultFinishedJobsDateFilter');
    const defaultFinishedJobsStatusSettings = getGoFSettings('defaultFinishedJobsStatusFilter');
    const defaultFinishedJobsOrderSettings = getGoFSettings('defaultFinishedJobsOrderFilter');
    const defaultFinishedJobsSkuSettings = getGoFSettings('defaultFinishedJobsSkuFilter');
    const defaultFinishedJobsStyleSettings = getGoFSettings('defaultFinishedJobsStyleFilter');
    const defaultFinishedJobsVariantSettings = getGoFSettings('defaultFinishedJobsVariantFilter');
    const defaultFinishedJobsMagentoSettings = getGoFSettings('defaultFinishedJobsMagentoFilter');
    const defaultFinishedJobsStyleCodeSettings = getGoFSettings('defaultFinishedJobsStyleCodeFilter');
    const defaultFinishedJobsStyleGroupCodeSettings = getGoFSettings('defaultFinishedJobsStyleGroupCodeFilter');
    const defaultFinishedJobsNoteSettings = getGoFSettings('defaultFinishedJobsNoteFilter');
    const defaultFinishedJobsStyleTypeSettings = getGoFSettings('defaultFinishedJobsStyleTypeFilter');
    const defaultFinishedJobsEndpointUrlSettings = getGoFSettings('defaultFinishedJobsEndpointUrlFilter');
    const defaultFinishedJobsCatalogRequestIdSettings = getGoFSettings('defaultFinishedJobsCatalogRequestIdFilter');
    const query = queryString.parse(window.location.search);

    // create query string in browser
    if (!query?.transactionType) {
        if (defaultFinishedJobsNameFilter && defaultFinishedJobsNameFilter.length) {
            addQuery('transactionType', defaultFinishedJobsNameFilter);
        }
    }

    if (!query?.start_date) {
        if (defaultFinishedJobsDateFilter?.start_date) {
            addQuery('start_date', defaultFinishedJobsDateFilter.start_date);
        }
        if (defaultFinishedJobsDateFilter?.end_date) {
            addQuery('end_date', defaultFinishedJobsDateFilter.end_date);
        }
        if (defaultFinishedJobsDateFilter?.duration) {
            addQuery('duration', defaultFinishedJobsDateFilter.duration);
        }
        if (defaultFinishedJobsDateFilter?.duration_units) {
            if (defaultFinishedJobsDateFilter?.duration_units.value) {
                addQuery('duration_units', defaultFinishedJobsDateFilter.duration_units.value);
            }
            else {
                addQuery('duration_units', defaultFinishedJobsDateFilter.duration_units);
            }
        }
    }

    if (!query?.status) {
        if (defaultFinishedJobsStatusSettings && defaultFinishedJobsStatusSettings.length) {
            addQuery('status', defaultFinishedJobsStatusSettings);
        }
    }

    if (!query?.orderNumber) {
        if (defaultFinishedJobsOrderSettings && defaultFinishedJobsOrderSettings.length) {
            addQuery('orderNumber', defaultFinishedJobsOrderSettings);
        }
    }

    if (!query?.sku) {
        if (defaultFinishedJobsSkuSettings && defaultFinishedJobsSkuSettings.length) {
            addQuery('sku', defaultFinishedJobsSkuSettings);
        }
    }

    if (!query?.styleId) {
        if (defaultFinishedJobsStyleSettings && defaultFinishedJobsStyleSettings.length) {
            addQuery('styleId', defaultFinishedJobsStyleSettings);
        }
    }

    if (!query?.variantId) {
        if (defaultFinishedJobsVariantSettings && defaultFinishedJobsVariantSettings.length) {
            addQuery('variantId', defaultFinishedJobsVariantSettings);
        }
    }

    if (!query?.magentoId) {
        if (defaultFinishedJobsMagentoSettings && defaultFinishedJobsMagentoSettings.length) {
            addQuery('magentoId', defaultFinishedJobsMagentoSettings);
        }
    }

    if (!query?.styleCode) {
        if (defaultFinishedJobsStyleCodeSettings && defaultFinishedJobsStyleCodeSettings.length) {
            addQuery('styleCode', defaultFinishedJobsStyleCodeSettings);
        }
    }

    if (!query?.styleGroupCode) {
        if (defaultFinishedJobsStyleGroupCodeSettings && defaultFinishedJobsStyleGroupCodeSettings.length) {
            addQuery('styleGroupCode', defaultFinishedJobsStyleGroupCodeSettings);
        }
    }

    if (!query?.note) {
        if (defaultFinishedJobsNoteSettings && defaultFinishedJobsNoteSettings.length) {
            addQuery('note', defaultFinishedJobsNoteSettings);
        }
    }

    if(!query?.styleType) {
        if (defaultFinishedJobsStyleTypeSettings && defaultFinishedJobsStyleTypeSettings.length) {
            addQuery('styleType', defaultFinishedJobsStyleTypeSettings);
        }
    }

    if(!query?.endpointUrl) {
        if (defaultFinishedJobsEndpointUrlSettings && defaultFinishedJobsEndpointUrlSettings.length) {
            addQuery('endpointUrl', defaultFinishedJobsEndpointUrlSettings);
        }
    }

    if(!query?.catalogRequestId) {
        if (defaultFinishedJobsCatalogRequestIdSettings && defaultFinishedJobsCatalogRequestIdSettings.length) {
            addQuery('catalogRequestId', defaultFinishedJobsCatalogRequestIdSettings);
        }
    }
}

export const getDefaultOrdersJobsFilters = (props=null) => {

    const orderJobTypesList = [];
    const defaultFilters = [];
    const yesterday = moment().subtract(1, 'days').format('yyyy-MM-DD') + ' 00:00';
    const today = moment().format('yyyy-MM-DD') + ' 23:59';
    const defaultDateFilter = {'columnName': 'started_at', start_date: yesterday, end_date: today };
    const qs = queryString.parse(window.location.search);

    const isOrdersScreen = props?.match?.path?.indexOf('order');

    const currentScreenPath = slugify(window.location.pathname);
    const defaultFinishedJobsSettings = getGoFSettings('defaultFinishedJobsFilters-'+ currentScreenPath);
    const defaultFinishedJobsDateFilter = getGoFSettings('defaultFinishedJobsDateFilter-'+ currentScreenPath);
    const defaultFinishedJobsDateRangeFilter = getGoFSettings('defaultFinishedJobsDateRangeFilter-'+ currentScreenPath);
    const defaultFinishedJobsNameFilter = getGoFSettings('defaultFinishedJobsNameFilter-'+ currentScreenPath);
    const defaultFinishedJobsStatusSettings = getGoFSettings('defaultFinishedJobsStatusFilters-'+ currentScreenPath);

    const isRunningBookmark = sessionStorage.getItem('current-bookmark') ? true : false;

    if ((isOrdersScreen > -1 && !qs.transactionType)) {
        orderJobTypes().forEach(orderJobType => {

            let jobTypeSplit = orderJobType.split('\\');
            let label = '';
            if (jobTypeSplit.length) {
                label = jobTypesMappedNames[jobTypeSplit[jobTypeSplit.length - 1]];
            }

            orderJobTypesList.push({ value: orderJobType, label: label });

        });

        defaultFilters.push({ columnName: 'name', value: orderJobTypesList })
    }
    else if (qs?.transactionType || isRunningBookmark) {
        if (qs?.transactionType) {
            qs?.transactionType.split(',').forEach(orderJobType => {

                let jobTypeSplit = orderJobType.split('\\');
                let label = '';
                let value = orderJobType;
                if (jobTypeSplit.length) {
                    label = jobTypesMappedNames[jobTypeSplit[jobTypeSplit.length - 1]];
                    value = jobTypeSplit[jobTypeSplit.length - 1];
                }

                if (label) {
                    orderJobTypesList.push({ value: value, label: label });
                }

            });

            defaultFilters.push({ columnName: 'name', value: orderJobTypesList })
        }
    }
    else if (!isRunningBookmark) {
        // fetch from localStorage if not coming from Bookmark
        if (defaultFinishedJobsNameFilter) {
            defaultFinishedJobsNameFilter.split(',').forEach(orderJobType => {
                if (orderJobType) {
                    let jobTypeSplit = orderJobType.split('\\');
                    let label = '';
                    let value = orderJobType;
                    if (jobTypeSplit.length) {
                        label = jobTypesMappedNames[jobTypeSplit[jobTypeSplit.length - 1]];
                        value = jobTypeSplit[jobTypeSplit.length - 1];
                    }

                    if (label) {
                        orderJobTypesList.push({ value: value, label: label });
                    }
                }
            });

            defaultFilters.push({ columnName: 'name', value: orderJobTypesList })
            addQuery('transactionType', defaultFinishedJobsNameFilter)
        }
    }


    if (isRunningBookmark) {
        // update with bookmark settings
        updateGofUserSettings(getGoFSettings(), 'defaultFinishedJobsNameFilter-'+ currentScreenPath, qs?.transactionType);
    }

    const statusList = [];

    if (qs?.status && qs?.status !== 'undefined') {
        qs?.status.split(',').forEach(statusType => {
            const label = getStatusLabel(statusType);
            statusList.push({value: statusType, label: label})
        });

        defaultFilters.push({columnName: 'status', value: statusList});
    }
    else if (defaultFinishedJobsStatusSettings && !isRunningBookmark) {
        defaultFinishedJobsStatusSettings.split(',').forEach(statusType => {
            const label = getStatusLabel(statusType);
            statusList.push({value: statusType, label: label})
        });

        defaultFilters.push({ columnName: 'status', value: statusList});
    }
    else if (!defaultFinishedJobsSettings) {
        defaultFilters.push({ columnName: 'status', value: ''});
    }

    if (isRunningBookmark) {
        // update with bookmark settings
        updateGofUserSettings(getGoFSettings(), 'defaultFinishedJobsStatusFilters-'+ currentScreenPath, qs?.status);
    }

    if (qs?.start_date) {
        defaultDateFilter.start_date = qs.start_date;
        addQuery('start_date', qs.start_date);
    } else {
        if (defaultFinishedJobsDateFilter) {
            defaultDateFilter.start_date = defaultFinishedJobsDateFilter.start_date;
            addQuery('start_date', defaultDateFilter.start_date);
        }
    }

    if (qs?.end_date) {
        defaultDateFilter.end_date = qs.end_date;
        addQuery('end_date', defaultDateFilter.end_date);
    } else {
        if (defaultFinishedJobsDateFilter) {
            defaultDateFilter.end_date = defaultFinishedJobsDateFilter.end_date;
            addQuery('end_date', defaultDateFilter.end_date);
        }
    }

    defaultFilters.push(defaultDateFilter);

    if (defaultFinishedJobsDateRangeFilter) {
        addQuery('date_range', defaultFinishedJobsDateRangeFilter);
    }

    if (qs?.orderNumber) {
        defaultFilters.push({ columnName: 'order_id', value: qs?.orderNumber })
    }

    return defaultFilters;
}

export const getDefaultSearchValue = () => {
    const qs = queryString.parse(window.location.search);

    let defaultSearchValue = '';

    if (qs?.search) {
        defaultSearchValue = qs?.search;
    }

    return defaultSearchValue;
}

export const getDefaultCatalogJobsFilters = (props) => {
    const defaultFilters = [];
    const catalogJobTypesList = [];
    const qs = queryString.parse(window.location.search);

    if (props.match.path.indexOf('catalog') > -1) {
        if (!qs.transactionType) {
            catalogJobTypes().forEach(catalogJobType => {

                let jobTypeSplit = catalogJobType.split('\\');
                let label = '';
                if (jobTypeSplit.length) {
                    label = jobTypesMappedNames[jobTypeSplit[jobTypeSplit.length - 1]];
                }

                catalogJobTypesList.push({ value: catalogJobType, label });

            });

            defaultFilters.push({ columnName: 'name', value: catalogJobTypesList })
        }
    }

    return defaultFilters;
}

export const getDefaultOtherJobsFilters = (props) => {
    const defaultFilters = [];
    const otherJobTypesList = [];
    const qs = queryString.parse(window.location.search);

    if (props.match.path.indexOf('other') > -1) {
        if (!qs.transactionType) {
            otherJobTypes().forEach(otherJobType => {
                let jobTypeSplit = otherJobType.split('\\');
                let label = '';
                if (jobTypeSplit.length) {
                    label = jobTypesMappedNames[jobTypeSplit[jobTypeSplit.length - 1]];
                }

                otherJobTypesList.push({ value: otherJobType, label });

            });
        }
        defaultFilters.push({ columnName: 'name', value: otherJobTypesList })
    }

    return defaultFilters;
}


export const getFinishJobTypeFilters = (props) => {
    return [...getFinishJobsFiltersFromQuery(), ...getDefaultCatalogJobsFilters(props), ...getDefaultOtherJobsFilters(props)];
}

export const getColumnChooserButton = (props) => {
    return (
        <button className="btn" onClick={props.onToggle} ref={props.buttonRef}>
            <CIcon name="cil-settings" size='xl' />
        </button>
    );

}

export const getJobPayloadsSettings = (job) => {
    return jobTypesMappedPayloadsOptions[getJobShortName(job)];
}

export const showDateRangeFilter = () => {
    const qs = queryString.parse(window.location.search);
    if (qs?.date_range) return true;
}

export const resolveDateRangeFilterValue = () => {
    const qs = queryString.parse(window.location.search);
    return qs?.date_range;
}

export const resolveFilterLabelAndValue = (filter, getClearFilters, setClearFilters, dispatch, setShowFilters) => {
    if (filter.columnName === 'status') {
        // status can now be 2 values like '1,0' so need to split into array
        if (Array.isArray(filter.value)) {
            let statusFilterValues = filter.value.map(val => val.label);
            const statusText = statusFilterValues.join(', ');

            if (statusText) {
                return <CButton key={`status-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Status: {statusText} <FontAwesomeIcon onClick={() => clearTheFilters(['status'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>;
            }
        }
    }
    else if (filter.columnName === 'order_id') {
        return filter.value && <CButton key={`order_id-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Order Number: {filter.value} <FontAwesomeIcon onClick={() => clearTheFilters(['order_id'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
    else if (filter.columnName === 'name') {
        if (filter.value.length > 0) {
            if (filter.value.length > 5) {
                return <CButton key={`name-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Name: {filter.value.length } selected <FontAwesomeIcon onClick={() => clearTheFilters(['name'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>

            }
            else {
                let nameFilter = filter.value.map(f => f.label);
                nameFilter = nameFilter.join(', ');
                return <CButton key={`name-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Name: {nameFilter} <FontAwesomeIcon onClick={() => clearTheFilters(['name'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
            }
        }
    }
    else if (filter.columnName === 'started_at') {
        if (filter.start_date || filter.end_date || (filter.duration && filter.duration_units)) {
            if (filter.duration && filter.duration_units) {
                return <CButton key={`date-${filter.duration}`} className="btn-pill mr-1 btn-dimmed-success"><>Look back {filter.duration} {filter.duration_units.value} <FontAwesomeIcon onClick={() => clearTheFilters(['start_date', 'end_date'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></></CButton>

            }
            else {
                return <CButton key={`date-${filter.start_date}`} className="btn-pill mr-1 btn-dimmed-success"><>{filter.start_date || 'Now'} - {filter.end_date || 'Now'} <FontAwesomeIcon onClick={() => clearTheFilters(['start_date', 'end_date'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></></CButton>
            }
        }
        else {
            // show All label
            return <CButton key={`date-all}`} className="btn-pill mr-1 btn-dimmed-success"> Date range: All </CButton>
        }
    }
    else if (filter.columnName === 'sku' && filter.value.length > 0) {
        return <CButton key={`sku-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">SKU: {filter.value } <FontAwesomeIcon onClick={() => clearTheFilters(['sku'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
    else if (filter.columnName === 'style_id' && filter.value.length > 0) {
        return <CButton key={`style-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Style ID: {filter.value } <FontAwesomeIcon onClick={() => clearTheFilters(['style_id'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
    else if (filter.columnName === 'variant_id' && filter.value.length > 0) {
        return <CButton key={`variant-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Variant ID: {filter.value } <FontAwesomeIcon onClick={() => clearTheFilters(['variant_id'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
    else if (filter.columnName === 'magento_id' && filter.value.length > 0) {
        return <CButton key={`magento-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Magento ID: {filter.value } <FontAwesomeIcon onClick={() => clearTheFilters(['magento_id'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
    else if (filter.columnName === 'style_code' && filter.value.length > 0) {
        return <CButton key={`style-code-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Style Code: {filter.value } <FontAwesomeIcon onClick={() => clearTheFilters(['style_code'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
    else if (filter.columnName === 'style_group_code' && filter.value.length > 0) {
        return <CButton key={`style-group-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Style Group Code: {filter.value } <FontAwesomeIcon onClick={() => clearTheFilters(['style_group_code'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
    else if (filter.columnName === 'style_type' && filter.value.length > 0) {
        return <CButton key={`style-type-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Style Type: {filter.value } <FontAwesomeIcon onClick={() => clearTheFilters(['style_type'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
    else if (filter.columnName === 'endpoint_url' && filter.value.length > 0) {
        return <CButton key={`endpoint-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Endpoint URL: {filter.value } <FontAwesomeIcon onClick={() => clearTheFilters(['endpoint_url'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
    else if (filter.columnName === 'note' && filter.value.length > 0) {
        return <CButton key={`note-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Note: {filter.value } <FontAwesomeIcon onClick={() => clearTheFilters(['note'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
    else if (filter.columnName === 'catalog_request_id' && filter.value.length > 0) {
        return <CButton key={`catalog-request-id-${filter.value}`} className="btn-pill mr-1 btn-dimmed-success">Catalog Request ID: {filter.value } <FontAwesomeIcon onClick={() => clearTheFilters(['catalog_request_id'], getClearFilters, setClearFilters, dispatch, setShowFilters)} icon={faTimes} /></CButton>
    }
}

export const clearTheFilters = (column, getter, setter, dispatch, setShowFilters) => {
    setter(!getter);
    setShowFilters(true);
    const toClear = {};
    column.forEach(d => toClear[d] = true);
    dispatch(commonActions.clearFinishedJobsFilters(toClear));

    setTimeout(() => { setShowFilters(false); }, 1500)
}

export const getRanges = () =>
{
    let  currentDate;

    currentDate = new Date(new Date());
    return  [
        {
            label: '1 hr',
            value: [subHours(currentDate, 1), currentDate]
        },
        {
            label: '3 hrs',
            value: [subHours(currentDate, 3), currentDate]
        },
        {
            label: '12 hrs',
            value: [subHours(currentDate, 12), currentDate]
        },
        {
            label: '1 day',
            value: [startOfDay(subDays(currentDate, 1)), currentDate]
        },
        {
            label: '3 days',
            value: [startOfDay(subDays(currentDate, 3)), currentDate]
        },
        {
            label: '7 days',
            value: [startOfDay(subDays(currentDate, 7)), currentDate]
        },
        {
            label: 'All',
            value: []
        }
    ];

}

export const ssApiJobs = () => {
    return [
        'App\\Jobs\\ShipStationPrepareDataJob',
        'App\\Jobs\\ShipStationCreateLabelJob',
        'App\\Jobs\\ShipStationShipmentJob',
        'App\\Jobs\\InStoreShipStationJob',
    ];
}

export const isSsApiJobFailedOnFirstTry = (job) => {
    if (!ssApiJobs().includes(job.name)) return false;
    if (job.attempt === 1) {
        return true;
    }
}

export const isSsApiJobInTheReTryProcessAfterFirstTry = (job) => {
    if (!ssApiJobs().includes(job.name)) return false;
    if (job.attempt > 1 && job.attempt < 7)  {
        return true;
    }
} 